import React from 'react'
// import styled from '@emotion/styled'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Image from '../components/image'
import { Box, Row, Column } from '../components/ui'

const IndexPage = () => (
  <Layout routePath="home">
    <SEO title="Home" />

    <Row justifyContent="center" mt={5} mb={3}>
      <Box fontSize={32} fontWeight={300}>
        Custom Shipping Containers
      </Box>
    </Row>

    <Row justifyContent="center" pb={5} fontSize={16} borderBottom="1px solid #eee">
      <Box maxWidth={800} mx="auto">
        Pro Pallet of West Fargo, North Dakota, specializes in manufacturing custom shipping crates
        and containers for domestic and international shipping. Prairie Wood Products manufactures
        stakes and lath in various sizes.
      </Box>
    </Row>

    <Row flexDirection={['column', 'row']}>
      <Column width={[1, 1 / 2]} px={3}>
        <Row justifyContent="center" py={42} fontWeight={300}>
          CUSTOM SHIPPING CRATES
        </Row>

        <Link to="/custom-shipping-containers">
          <ImageWithCaption filename="home-1.jpg" alt="" caption="Oilfield Flare Crates" />
        </Link>

        <Box mt={3} fontSize={14}>
          If it's large, awkward, heavy or sensitive we can package it. We design and build custom
          shipping containers ready to safely transport your items internationally.
        </Box>
      </Column>

      <Column width={[1, 1 / 2]} px={3}>
        <Row justifyContent="center" py={42} fontWeight={300}>
          PRAIRIE WOOD PRODUCTS OF MN
        </Row>

        <Link to="/prairie-wood-products">
          <ImageWithCaption filename="home-2.jpg" alt="" caption="Stake Bundles" />
        </Link>

        <Box mt={3} fontSize={14}>
          Prairie Wood Products of MN makes stakes and lath of varying sizes. All stakes and lath
          are made from kiln dried wood.
        </Box>
      </Column>
    </Row>
  </Layout>
)

export default IndexPage

const ImageWithCaption = ({ filename, alt = '', caption }) => (
  <Box position="relative">
    <Image filename={filename} alt={alt} />
    <Box
      position="absolute"
      display="flex"
      alignItems="center"
      // justifyContent="center"
      bottom={0}
      width={1}
      height={52}
      px={3}
      bg="#000"
      color="#fff"
      fontSize={12}
      css={{ opacity: '0.7', textTransform: 'uppercase' }}
    >
      {caption}
    </Box>
  </Box>
)
